import React ,{useState}from 'react';
import './Service.css'
const Service = () => {
const [open,setOpen]=useState(false)
  return (
    <div className='Service'>
      <h3 onClick={()=>setOpen(!open)}>About ONNO.AI</h3>
      <div className={open?'des':'none'}>
      <span className='description'>
      Maximize YouTube with Onno, your AI-powered assistant for quick video summaries and 
      translations. Using advanced AI and language translation technologies, Onno saves you 
      time and breaks down language barriers, making learning and entertainment more efficient 
      and tailored to your pace.
 

      </span>
      <h3>How It Work</h3>
      <ol>
        <li>Install the Onno extension and navigate to any YouTube video. </li>
        <li>Click the Onno icon to generate a summary of the video content. </li>
        <li>If needed, select your preferred language for translation.</li>
        <li>Enjoy a concise summary and decide how you want to proceed with your viewing.
         Empower Your YouTube Experience: With Onno, you're not just watching 
         videos; you're efficiently absorbing information, breaking down language 
         barriers, and tailoring your learning and entertainment to fit your busy life. </li>
      </ol>
      <h3>Key Features</h3>
      <ul>
        <li> Instant Summaries:<br/>
            With Onno, gain instant access to brief, insightful summaries of 
            any YouTube video. Powered by the best-in-class AI technologies of 
            ChatGPT and Google Gemini, Onno distills the essence of videos, 
            helping you understand content faster than ever before.</li>

        <li>Language 
          Translation:<br/>
           Onno breaks language barriers by offering translations of 
          summaries into your language of choice. Whether it's a trending
           international video or a niche documentary, Onno ensures you won't 
           miss out on any content, regardless of the original language.</li>
        <li>
        Time-Saving: <br/>
        Designed for efficiency, Onno saves you time by providing quick overviews, 
        allowing you to decide whether to dive deeper into the full content. 
        It's perfect for students, professionals, and anyone looking to optimize 
        their YouTube browsing experience. 
        </li>
        <li>
        User-Friendly Interface:<br/>
        Onno is incredibly easy to use. With just a simple click, you can generate 
        summaries and translations directly within YouTubeâ€™s platform, seamlessly 
        integrating into your video watching routine.
        </li>
      </ul>
      </div>
    </div>
  );
}

export default Service;
