import AccessingGoogle from './pages/AccessingGoogle/AccessingGoogle';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import GoToService from './pages/GoToService/GoToService';
import Service from './pages/Service/Service';
import './App.css';

function App() {
  return (
    <div className="App">
    <h1>
        <img className='onnoIcon' src='../48.png'/>
        ONNO.AI
      </h1>
    <div className='des'>
    <Service/>
    <GoToService/>
    <PrivacyPolicy/>
     <AccessingGoogle/>
    </div>
    
    <footer style={{marginTop:'80px'}}>
      <p>&copy; 2024ONNO.AI</p> <p>Email support at <a href="mailto:hello@onno.ai">hello@onno.ai</a></p>   
      </footer>
    </div>
  );
}

export default App;
