import React ,{useState}from 'react';
import './AccessingGoogle.css'

const AccessingGoogle = () => {
  const [open,setOpen]=useState(false)
  return (
    <div className='AccessingGoogle'>
          <h3 onClick={()=>setOpen(!open)}>Accessing Google User Data</h3>
          <div className={open?'des':'none'}>
            <span>
            Onno adheres to the Google API Services User Data Policy, ensuring responsible use and transfer of information received from Google APIs, compliant with Limited Use requirements. Learn more at
             <a href='https://developers.google.com/terms/api-services-user-data-policy'> Google API Services User Data Policy</a>..
            </span>
          </div>
    </div>
  );
}

export default AccessingGoogle;
