import React ,{useState}from 'react';
import './GoToService.css'

const GoToService = () => {
  const [open,setOpen]=useState(false)
  return (
    <div className='GoToService'>
          <h3 onClick={()=>setOpen(!open)}>Go to ONNO.AI</h3>
          <div className={open?'des':'none'}>
            <span>
            <h4>CHROME EXTENSION</h4>
              <a className='aTag' href="https://chromewebstore.google.com/detail/onno-ai/jdjcifgkfajhdagndfmlhdmhimhicddc?hl=en-US&utm_source=ext_sidebar">
              <img width={40} className='onnoIcon' src='../icon.png'/>
                ONNO AI 
              </a>
              

 
            </span>
          </div>
    </div>
  );
}

export default GoToService;
