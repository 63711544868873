import React ,{useState}from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  const [open,setOpen]=useState(false)
  return (
    <div className='PrivacyPolicy'>
      <h3 onClick={()=>setOpen(!open)}>Privacy Policy</h3>
      <div className={open?'des':'none'}>
      <span>
      Privacy Policy for Onno

Effective Date: 05/26/2024
<br/>
<h3>1. Introduction</h3>

Onno ("we", "our", "us") respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you use our Chrome extension ("Onno") and our practices for collecting, using, maintaining, protecting, and disclosing that information.
<br/>
<h3>2. Information We Collect</h3>

We collect several types of information from and about users of our Service, including:<br/>
- Personal Information: When you use Onno, we may collect personal information that can identify you, such as your name, email address, and profile image. This information is accessed through Google OAuth with the following scopes: `/auth/userinfo.email`, `/auth/userinfo.profile`.<br/>
- Non-Personal Information: We may collect non-personal information about your interactions with Onno, such as usage statistics and preferences.<br/>

<h3>3. How We Use Your Information</h3>

We use information that we collect about you or that you provide to us, including any personal information:<br/>
- To provide, maintain, and improve our services.<br/>
- To manage your account and provide you with customer support.<br/>
- To perform research and analysis about your use of, or interest in, our services.<br/>
- To enforce our terms and conditions.<br/>
<br/>
<h3>4. Data Sharing and Disclosure</h3>

We do not share or disclose your personal information to third parties except as described in this policy:<br/>
- With Your Consent**: We may disclose your personal information for any purpose with your explicit consent.<br/>
- Service Providers**: We may share your information with service providers who perform services on our behalf, such as payment processing and data analysis, under strict confidentiality agreements.<br/>
- Legal Requirements**: We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations.<br/>
<br/>
<h3>5. Data Security</h3>

We have implemented measures designed to secure your personal information from accidental loss and unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on secure servers behind firewalls.<br/>
<br/>
<h3>6. Accessing and Controlling Your Information</h3>

You can review and change your personal information by logging into the Service and visiting your account profile page. You may also contact us to request access to, correct, or delete any personal information that you have provided to us.
<br/>
<h3>7. Changes to Our Privacy Policy</h3>

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

<h3>8. Contact Information</h3>

If you have any questions , please contact us at:<br/>
- Email: hello@onno.ai


<h3>9. Compliance with Google API Services User Data Policy</h3>

Onno adheres to the Google API Services User Data Policy, ensuring responsible use
 and transfer of information received from Google APIs, compliant with Limited Use requirements.
  We use Google OAuth to access only essential user data for credit management and service improvement. 
  Your data is protected and won't be shared without explicit consent. For detailed policies.<br/>

       visit <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>.
      </span>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
